import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    this.clear()
    this.toggleButtonVisibility()
  }

  change() {
    this.toggleButtonVisibility()

    // debounce search
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.search()
    }, 300)
  }

  search() {
    Rails.ajax({
      type: "GET",
      dataType: "script",
      url: "/map/search",
      data: new URLSearchParams({'query': this.inputTarget.value}).toString(),
      success: function(data) {
        const html = new XMLSerializer().serializeToString(data)
        document.getElementById("search-results").innerHTML = html
      }
    })
  }

  clear() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.inputTarget.value = ""
      document.getElementById("search-results").innerHTML = ""
    }, 10)
  }

  toggleButtonVisibility() {
    if (this.inputTarget.value.length > 0) {
      this.buttonTarget.classList.remove("hidden")
    } else {
      this.buttonTarget.classList.add("hidden")
    }
  }
}
